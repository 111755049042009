<template>
  <b-modal
    :id="`block-payment-modal${tripid}${blockid}`"
    centered
    scrollable
    size="lg"
    title="Block Payment Modal"
    :hide-footer="true"
  >
    <div style="max-height: 500px">
      <b-table
        striped
        hover
        responsive
        class="position-relative"
        :items="payments"
        :fields="fields"
      >
        <template #cell(ID)="row">
          <span v-if="row.item.load_id">Load ID {{ row.item.load_id }}</span>
          <span v-else>Block ID {{ row.item.block_id }}</span>
        </template>

        <template #cell(base_rate)="row">
          ${{ row.item.base_rate.toFixed(2) }}
        </template>

        <template #cell(fuel_surcharge)="row">
          ${{ row.item.fuel_surcharge.toFixed(2) }}
        </template>

        <template #cell(tolls)="row">
          ${{ row.item.tolls.toFixed(2) }}
        </template>

        <template #cell(gross_pay)="row">
          ${{ row.item.gross_pay.toFixed(2) }}
        </template>
      </b-table>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal, BTable,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BTable,
  },
  props: {
    payments: {
      type: Array,
      default() {
        return []
      },
    },
    tripid: {
      type: String,
      default: '',
    },
    blockid: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      fields: [
        { key: 'ID', label: 'ID' },
        { key: 'base_rate', label: 'Base Rate' },
        { key: 'fuel_surcharge', label: 'Fuel surCharge' },
        { key: 'tolls', label: 'Toll charge' },
        { key: 'gross_pay', label: 'Total' },
      ],
    }
  },
  methods: {
    
  },
}
</script>

<style>

</style>
