import axios from 'axios'
import api from '@/utils/endpoints'
import dotenv from 'dotenv'
import moment from 'moment'

dotenv.config()

const headers = {
  Accept: 'application/json',
  Authorization: `Bearer ${localStorage.getItem('token')}`,
}

export const getPayments = () => new Promise((resolve, reject) => {
  axios
    .get(`${process.env.VUE_APP_API_URL}/${api.payment.get}`, { headers })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
})

export const importCSV = params => {
  /** get payment summary and details */
  const summary = params.payload[0]
  const details = params.payload[1]
  const companyId = localStorage.getItem('companyId')
  /** create payload for invoice */
  let invoice = {}
  summary.forEach(item => {
    const array = Object.values(item)
    array.forEach((obj, index) => {
      if (obj === 'Dispute Id::') {
        invoice = {
          ...invoice,
          dispute_id: array[index + 1],
          company_id: companyId,
        }
      }
      if (obj === 'Invoice #::') {
        invoice = {
          ...invoice,
          invoice_number: array[index + 1],
          company_id: companyId,
        }
      }
      if (obj === 'Invoice date::') {
        invoice = {
          ...invoice,
          invoice_date: moment(array[index + 1]).format('yyyy-MM-DD'),
          company_id: companyId,
        }
      }
      if (obj === 'Invoice total::') {
        invoice = {
          ...invoice,
          invoice_total: array[index + 1],
          company_id: companyId,
        }
      }
      if (obj === 'Work period::') {
        invoice = {
          ...invoice,
          work_period: array[index + 1],
          company_id: companyId,
        }
      }
      if (obj === 'Payment Status::') {
        invoice = {
          ...invoice,
          payment_status: array[index + 1],
          company_id: companyId,
        }
      }
      if (obj === 'Payment date::') {
        invoice = {
          ...invoice,
          payment_date: moment(array[index + 1]).format('yyyy-MM-DD'),
          company_id: companyId,
        }
      }
      if (obj === 'Work type::') {
        invoice = {
          ...invoice,
          work_type: array[index + 1],
          company_id: companyId,
        }
      }
      if (obj === 'Pay Term::') {
        invoice = {
          ...invoice,
          pay_term: array[index + 1],
          company_id: companyId,
        }
      }
    })
  })

  /** create payload for payment details */
  const payments = []
  details.forEach((detail, index) => {
    if (index < details.length - 2) {
      let blockID = null
      if (detail['Block Id']) {
        blockID = detail['Block Id']
      } else if (detail['Block ID']) {
        blockID = detail['Block ID']
      } else {
        blockID = null
      }
      payments.push({
        invoice_number: detail['Invoice Number'] ? detail['Invoice Number'] : '',
        trip_id: detail['Trip ID'] ? detail['Trip ID'] : '',
        block_id: blockID,
        contract_id: detail['Contract ID'] ? detail['Contract ID'] : '',
        calendar_week: detail['Calendar Week'] ? detail['Calendar Week'] : '',
        distance: invoice.work_type === 'SPOT' || invoice.work_type === 'TENDERED' ? detail['Distance (Mi)'] : detail['Distance (mi)'],
        start_date: detail['Start Date'] ? moment(detail['Start Date']).format('yyyy-MM-DD') : null,
        end_date: detail['End Date'] ? moment(detail['End Date']).format('yyyy-MM-DD') : null,
        equipment: detail.Equipment ? detail.Equipment : '',
        item_type: detail['Item Type'] ? detail['Item Type'] : '',
        load_id: detail['Load ID'] ? detail['Load ID'] : '',
        operator_type: detail['Operator Type'] ? detail['Operator Type'] : '',
        others: detail.Others ? detail.Others : 0,
        program_type: detail['Program Type'] ? detail['Program Type'] : '',
        // eslint-disable-next-line no-nested-ternary
        route: invoice.work_type === 'SPOT' ? detail.Route : invoice.work_type === 'TENDERED' ? detail['Route/Domicile'] : detail['Run/Domicile'],
        base_rate: detail['Base Rate'] ? detail['Base Rate'] : 0,
        detention: detail.Detention ? detail.Detention : 0,
        tonu: detail.TONU ? detail.TONU : 0,
        tolls: detail.Tolls ? detail.Tolls : 0,
        fuel_surcharge: detail['Fuel Surcharge'] ? detail['Fuel Surcharge'] : 0,
        gross_pay: detail['Gross Pay'] ? detail['Gross Pay'] : 0,
        hourly_rate: detail['Hourly Rate'] ? detail['Hourly Rate'] : 0,
        duration: detail['Duration (hrs)'] ? detail['Duration (hrs)'] : 0,
        variable_total: detail['Variable Total'] ? detail['Variable Total'] : 0,
        number_of_tractors: detail['# of Tractors'] ? detail['# of Tractors'] : '',
        cost_per_tractor: detail['Cost Per Tractor'] ? detail['Cost Per Tractor'] : 0,
        comments: detail.Comments ? detail.Comments : '',
        company_id: companyId,
      })
    }
  })

  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.VUE_APP_API_URL}/${api.payment.importCSV}`, { invoice, payments }, { headers })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const invoiceDetail = id => new Promise((resolve, reject) => {
  axios
    .get(`${process.env.VUE_APP_API_URL}/${api.payment.detail}/${id}`, { headers })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
})

export const getPaymentOfTrip = params => {
  const { blockid, tripid, loadIds } = params
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.VUE_APP_API_URL}/${api.payment.getPaymentOfTrip}`, { blockid, tripid, loadIds }, { headers })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}
