<template>
  <b-card>
    <b-row class="w-100 margin-0">
      <div
        v-for="(item, index) of items"
        :key="index"
        class="w-100"
      >
        <!-- detail of stop -->
        <b-row
          class="d-flex mb-2 margin-0"
          style="background-color: #f3f2f7; padding: 10px;"
          @click="handleDetailView(index + item.trip_id)"
        >

          <!-- Dropdown Icon -->
          <b-col
            md="1"
            class="d-flex align-items-center"
          >
            <feather-icon
              :icon="selected == index + item.trip_id && open ? 'ChevronUpIcon' : 'ChevronDownIcon'"
              size="21"
            />
          </b-col>

          <!-- Load ID -->
          <b-col
            md="2"
            class="d-flex align-items-center font-weight-bold"
          >
            <div style="color: darkgreen; font-weight: 600;">
              {{ item.load_id }}
            </div>
          </b-col>

          <!-- Stop 1 to Stop2 -->
          <b-col
            md="3"
            class="d-flex align-items-center font-weight-bold"
          >

            <!-- Stop 1 Name -->
            <div
              class="d-flex align-items-center"
              style="color: darkgreen;"
            >
              <b-avatar
                variant="secondary"
                :text="`${index + 1}`"
                class="mr-1"
              />
              {{ item.stop_1 }}
            </div>

            <feather-icon
              icon="ArrowRightIcon"
              size="21"
              class="mx-1"
            />

            <!-- Stop 2 Name -->
            <div
              class="d-flex align-items-center"
              style="color: darkgreen;"
            >
              <b-avatar
                variant="secondary"
                :text="`${index + 2}`"
                class="mr-1"
              />
              {{ item.stop_2 }}
            </div>

          </b-col>

          <!-- Distance and Time -->
          <b-col
            md="2"
            class="font-weight-bold"
          >
            <div>{{ item.estimate_distance + ' ' + item.unit }}</div>
            <div>{{ item.cost_time }}</div>
          </b-col>

          <!-- Driver Name -->
          <b-col
            md="2"
            class="d-flex align-items-center font-weight-bold"
          >
            {{ item.driver_name }}
          </b-col>

          <!-- Status -->
          <b-col
            md="2"
            class="d-flex align-items-center font-weight-bold"
          >
            <feather-icon
              :icon="item.load_execution_status === 'Completed' ? 'CheckIcon' : 'XIcon'"
              :color="item.load_execution_status === 'Completed' ? 'green' : 'red'"
              size="18"
            />

            <feather-icon
              :icon="item.invoice_status? 'DollarSignIcon' : 'XIcon'"
              :color="item.invoice_status? 'green' : 'red'"
              size="18"
            />
          </b-col>

        </b-row>

        <!--  detail content -->
        <b-row
          :id="index + item.trip_id"
          class="w-100 margin-0"
          :class="selected == index + item.trip_id && open ? '' : 'd-none'"
        >
          <!--  detail header -->
          <b-row
            class="mb-2 text-uppercase pb-1 w-100 margin-0"
            style="border-bottom: 2px solid darkgreen; color: darkgreen;"
          >
            <b-col md="3">
              Stop
            </b-col>
            <b-col md="3">
              Equipment/ID
            </b-col>
            <b-col md="3">
              Arrival
            </b-col>
            <b-col md="3">
              Departure
            </b-col>
          </b-row>

          <!-- stop 1 -->
          <b-row class="mb-2 w-100 margin-0">

            <!-- stop 1 driver name -->
            <b-col md="3">
              <div class="d-flex align-items-center">
                <b-avatar
                  variant="secondary"
                  text="1"
                />
                <div class="font-weight-bold ml-1">
                  {{ item.stop_1 }}
                </div>
              </div>
              <div
                v-if="item.stop1"
                class="mt-1"
                style="font-size: 12px;"
              >
                <span>{{ `${item.stop1.address ? item.stop1.address + ' ': ''}` }}</span><br>
                <span>
                  {{
                    `${item.stop1.city ? item.stop1.city + ', ' : ''}` +
                      `${item.stop1.abbreviated_state ? item.stop1.abbreviated_state + ', ' : ''}` +
                      `${item.stop1.zipcode ? item.stop1.zipcode : ''}`
                  }}
                </span>
              </div>
            </b-col>

            <!-- stop 1 trator id, equipment type, trailer id -->
            <b-col md="3">
              <div>Tractor ID {{ item.tractor_vehicle_id }}</div>
              <div>{{ item.equipment_type }}</div>
              <div>Trailer ID {{ item.sub_carrier + ' ' + item.trailer_id }}</div>
            </b-col>

            <!-- stop 1 arrival date and time -->
            <b-col md="3">
              <div v-if="item.stop_1_actual_arrival_datetime">
                {{ item.stop_1_actual_arrival_datetime }}
                <feather-icon
                  v-b-tooltip.hover.right="
                    `Scheduled: ${item.stop_1_planned_arrival_datetime}
                    Actual: ${item.stop_1_actual_arrival_datetime}`
                  "
                  icon="PlusIcon"
                  size="21"
                  class="mx-1"
                />
              </div>
            </b-col>

            <!-- stop 1 departure date and time -->
            <b-col md="3">
              <div v-if="item.stop_1_actual_departure_datetime">
                {{ item.stop_1_actual_departure_datetime }}
                <feather-icon
                  v-b-tooltip.hover.right="
                    `Scheduled: ${item.stop_1_planned_departure_datetime}
                    Actual: ${item.stop_1_actual_departure_datetime}`
                  "
                  icon="PlusIcon"
                  size="21"
                  class="mx-1"
                />
              </div>
            </b-col>

          </b-row>

          <!-- stop 2 -->
          <b-row class="mb-2 w-100 margin-0">

            <!-- stop 2 driver name -->
            <b-col md="3">
              <div class="d-flex align-items-center">
                <b-avatar
                  variant="secondary"
                  text="2"
                />
                <div class="font-weight-bold ml-1">
                  {{ item.stop_2 }}
                </div>
              </div>
              <div
                v-if="item.stop2"
                class="mt-1"
                style="font-size: 12px;"
              >
                <span>{{ `${item.stop2.address ? item.stop2.address + ' ': ''}` }}</span><br>
                <span>
                  {{
                    `${item.stop2.city ? item.stop2.city + ', ' : ''}` +
                      `${item.stop2.abbreviated_state ? item.stop2.abbreviated_state + ', ' : ''}` +
                      `${item.stop2.zipcode ? item.stop2.zipcode : ''}`
                  }}
                </span>
              </div>
            </b-col>

            <!-- stop 2 tractor id, equipment type, trailer id -->
            <b-col md="3">
              <div>Tractor ID {{ item.tractor_vehicle_id }}</div>
              <div>{{ item.equipment_type }}</div>
              <div>Trailer ID {{ item.sub_carrier + ' ' + item.trailer_id }}</div>
            </b-col>

            <!-- stop 2 arrival date and time -->
            <b-col
              md="3"
              class="d-flex align-items-center"
            >
              <div v-if="item.stop_2_actual_arrival_datetime">
                {{ item.stop_2_actual_arrival_datetime }}
                <feather-icon
                  v-b-tooltip.hover.right="
                    `Scheduled: ${item.stop_2_planned_arrival_datetime}
                    Actual: ${item.stop_2_actual_arrival_datetime}`
                  "
                  icon="PlusIcon"
                  size="21"
                  class="mx-1"
                />
              </div>
            </b-col>

            <!-- stop 2 departure date and time -->
            <b-col
              md="3"
              class="d-flex align-items-center"
            >
              <div v-if="item.stop_2_actual_departure_datetime">
                {{ item.stop_2_actual_departure_datetime }}
                <feather-icon
                  v-b-tooltip.hover.right="
                    `Scheduled: ${item.stop_2_planned_departure_datetime}
                    Actual: ${item.stop_2_actual_departure_datetime}`
                  "
                  icon="PlusIcon"
                  size="21"
                  class="mx-1"
                />
              </div>
            </b-col>
          </b-row>
        </b-row>
      </div>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BAvatar, VBTooltip, BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BAvatar,
    BCard,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      selected: '',
      open: false,
    }
  },
  methods: {
    /** handle Detail View */
    handleDetailView(idValue) {
      this.selected = idValue
      const element = document.getElementById(idValue)
      if (element.classList.contains('d-none')) this.open = true
      else this.open = false
    },
  },
}
</script>

<style>

</style>
