<template>
  <div>
    <div class="d-flex justify-content-between flex-wrap mb-2 px-2">
      <!-- sorting  -->
      <b-form-group>

        <b-input-group size="sm">

          <b-form-select
            id="sortBySelect"
            v-model="sortBy"
            :options="sortOptions"
          >
            <template #first>
              <option value="">
                none
              </option>
            </template>
          </b-form-select>

          <b-form-select
            v-model="sortDesc"
            size="sm"
            :disabled="!sortBy"
          >
            <option :value="false">
              Asc
            </option>
            <option :value="true">
              Desc
            </option>
          </b-form-select>

        </b-input-group>

      </b-form-group>

      <!-- filter -->
      <b-form-group
        label=""
        label-cols-sm="2"
        label-align-sm="left"
        label-size="sm"
        label-for="filterInput"
        class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Type to Search"
          />
          <b-input-group-append>
            <b-button
              :disabled="!filter"
              @click="filter = ''"
            >
              Clear
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </div>
        
    <b-table
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
    >
      <template #cell(show_details)="row">

        <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
        <b-form-checkbox
          v-model="row.detailsShowing"
          @change="row.toggleDetails"
        >
          {{ row.detailsShowing ? 'Hide' : 'Show' }}
        </b-form-checkbox>
      </template>

      <!-- full detail on click -->
      <template #row-details="row">

        <!-- table detail view -->
        <table-detail-view
          :items="row.item.detailviews"
        />

        <!-- footer button for payment details and comment -->
        <table-footer-buttons
          :blockid="row.item.shortviews.block_id"
          :tripid="row.item.shortviews.trip_id"
          :loads="row.item.detailviews"
        />
      </template>

      <!-- Block Id-->
      <template #cell(block_id)="row">
        <div class="text-uppercase text-info font-weight-bold">
          {{ row.item.shortviews.block_id }}
        </div>
        <div>{{ row.item.shortviews.type }}</div>
      </template>

      <!-- Sequence -->
      <template #cell(facility_sequence)="row">
        <div class="d-flex">

          <div class="d-flex align-items-center">
            <b-avatar
              variant="secondary"
              text="1"
            />
            <div class="ml-1">
              {{ row.item.shortviews.start_point }}
            </div>
          </div>
                    
          <div class="d-flex align-items-center ml-1 mr-1">
            <feather-icon
              icon="ArrowRightIcon"
              size="21"
            />
          </div>

          <div class="d-flex align-items-center">
            <b-avatar
              variant="secondary"
              :text="`${row.item.shortviews.count + 1}`"
            />
            <div class="ml-1">
              {{ row.item.shortviews.end_point }}
            </div>
          </div>

        </div>
      </template>

      <!-- Total Time -->
      <template #cell(distance_and_time)="row">
        {{ `${row.item.shortviews.total_estimate_distance ? row.item.shortviews.total_estimate_distance.toFixed(2) : 0}` + ' ' + row.item.shortviews.unit }}
        <br>{{ row.item.shortviews.total_time }}
      </template>

      <!-- Equipment Type -->
      <template #cell(equipment_type)="row">
        {{ row.item.shortviews.equipment_type }}
      </template>

      <!-- Price -->
      <template #cell(price)="row">
        {{ `${row.item.shortviews.total_estimated_cost ? row.item.shortviews.total_estimated_cost.toFixed(2) : 0}` + ' ' + row.item.shortviews.currency }}
      </template>

      <!-- Driver Name -->
      <template #cell(driver_name)="row">
        {{ row.item.shortviews.driver_name }}
      </template>

      <!-- Status -->
      <template #cell(status)="row">
        <div class="d-flex">
          <div>
            <b-badge
              v-if="row.item.shortviews.count === row.item.shortviews.wrong"
              variant="danger"
            >
              {{ row.item.shortviews.status }}
            </b-badge>
            <b-badge
              v-else-if="row.item.shortviews.status === 'Completed' && row.item.shortviews.wrong === 0"
              variant="success"
            >
              {{ `${row.item.shortviews.count}/${row.item.shortviews.count} Loads` }}
            </b-badge>
            <b-badge
              v-else-if="row.item.shortviews.status === 'Completed' && row.item.shortviews.wrong > 0"
              variant="warning"
            >
              {{ `${row.item.shortviews.count - row.item.shortviews.wrong}/${row.item.shortviews.count} Loads` }}
            </b-badge>
          </div>
          <div>
            <b-badge
              :variant="row.item.shortviews.count === row.item.shortviews.num_invoices ? 'success' : 'warning'"
            >
              {{ `${row.item.shortviews.num_invoices}/${row.item.shortviews.count} Invoiced` }}
            </b-badge>
          </div>
        </div>
      </template>

    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
        label=""
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
  </div>
</template>

<script>
import {
  BTable, BFormCheckbox, BButton, BFormGroup, BFormSelect, BCardBody, BAvatar, BBadge, BPagination, VBTooltip, BInputGroup, BFormInput, BInputGroupAppend,
} from 'bootstrap-vue'
import TableDetailView from './TableDetailView.vue'
import TableFooterButtons from './TableFooterButtons.vue'

export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BFormGroup,
    BFormSelect,
    BCardBody,
    BBadge,
    BAvatar,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    TableDetailView,
    TableFooterButtons,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        'show_details',
        { key: 'block_id', label: 'Block ID', sortable: true },
        { key: 'facility_sequence', label: 'Sequence' },
        { key: 'distance_and_time', label: 'Distance and Time' },
        { key: 'equipment_type', label: 'Equipment Type' },
        { key: 'price', label: 'Price', sortable: true },
        { key: 'driver_name', label: 'Driver Name', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
      ],
      perPage: 30,
      pageOptions: [30, 40, 50],
      totalRows: 1,
      currentPage: 1,
      status: [],
      showDetail: false,
    }
  },

  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },

  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },

  methods: {
    /** Filter function */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-sweetalert.scss';

  .margin-0 {
    margin: 0px;
  }
</style>
