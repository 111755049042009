import axios from 'axios'
import moment from 'moment'
import api from '@/utils/endpoints'
import dotenv from 'dotenv'

dotenv.config()

const headers = {
  Accept: 'application/json',
  Authorization: `Bearer ${localStorage.getItem('token')}`,
}

// conver date
export const convertDate = (date, time) => {
  let convert = null
  if (date && time) {
    convert = moment(`${date} ${time}`).format('yyyy-MM-DD HH:mm')
  } else {
    convert = ''
  }

  if (convert === 'Invalid date') {
    convert = ''
  }

  return convert
}

// get Trip lists
export const getTrips = params => {
  const { start, end } = params
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.VUE_APP_API_URL}/${api.trip.get}`, { start, end }, { headers })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

// import trip csv
export const importCSV = params => {
  const { payload, start, end } = params
  const trips = []
  const companyId = localStorage.getItem('companyId')
  /** create payload */
  payload.forEach(item => {
    trips.push({
      block_id: item['Block ID'],
      block_type: item['Block/Trip'],
      cr_id: item.CR_ID,
      contract_id: item['Contract ID'],
      contract_type: item['Contract Type'],
      currency: item.Currency,
      driver_name: item['Driver Name'],
      equipment_type: item['Equipment Type'],
      estimate_distance: item['Estimate Distance'],
      estimated_cost: item['Estimated Cost'],
      facility_sequence: item['Facility Sequence'],
      load_execution_status: item['Load Execution Status'],
      load_id: item['Load ID'],
      operator_id: item['Operator ID'],
      rate_type: item['Rate Type'],
      shipper_account: item['Shipper Account'],
      spot_work: item['Spot Work'],
      stop_1: item['Stop 1'],
      stop_1_actual_arrival_datetime: convertDate(item['Stop 1  Actual Arrival Date'], item['Stop 1  Actual Arrival Time']),
      stop_1_actual_departure_datetime: convertDate(item['Stop 1 Actual Departure Date'], item['Stop 1 Actual Departure Time']),
      stop_1_planned_departure_datetime: convertDate(item['Stop 1  Planned Departure Date'], item['Stop 1  Planned Departure Time']),
      stop_1_planned_arrival_datetime: convertDate(item['Stop 1 Planned Arrival Date'], item['Stop 1 Planned Arrival Time']),
      stop_1_utc_offset: item['Stop 1 UTC Offset'],
      stop_2: item['Stop 2'],
      stop_2_actual_arrival_datetime: convertDate(item['Stop 2  Actual Arrival Date'], item['Stop 2  Actual Arrival Time']),
      stop_2_actual_departure_datetime: convertDate(item['Stop 2 Actual Departure Date'], item['Stop 2 Actual Departure Time']),
      stop_2_planned_departure_datetime: convertDate(item['Stop 2  Planned Departure Date'], item['Stop 2  Planned Departure Time']),
      stop_2_planned_arrival_datetime: convertDate(item['Stop 2 Planned Arrival Date'], item['Stop 2 Planned Arrival Time']),
      stop_2_utc_offset: item['Stop 2 UTC Offset'],
      sub_carrier: item['Sub Carrier'],
      tractor_vehicle_id: item['Tractor Vehicle ID'],
      trip_id: item['Trip ID'],
      trip_stage: item['Trip Stage'],
      transit_operator_type: item['Transit Operator Type'],
      trailer_id: item['Trailer ID'],
      truck_filter: item['Truck Filter'],
      unit: item.Unit,
      weekStart: start,
      weekEnd: end,
      company_id: companyId,
    })
  })

  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.VUE_APP_API_URL}/${api.trip.importCSV}`, { trips, start, end }, { headers })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

// format trip of week
export const format = params => {
  const { start, end } = params
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.VUE_APP_API_URL}/${api.trip.format}`, { start, end }, { headers })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}
