<template>
  <div class="d-flex justify-content-start">
    <div class="bg-white py-1 px-1">
      <b-button-group>
        <b-button
          variant="outline-secondary"
          class="btn-icon"
          @click="viewPayment()"
        >
          <feather-icon
            icon="DollarSignIcon"
            size="15"
          />
        </b-button>
        <b-button
          variant="outline-secondary"
          class="btn-icon"
        >
          <feather-icon
            icon="FileTextIcon"
            size="15"
          />
        </b-button>
      </b-button-group>
    </div>

    <block-payment-modal
      :payments="payments"
      :blockid="blockid"
      :tripid="tripid"
    />
  </div>
</template>

<script>
import {
  BButtonGroup, BButton, VBModal,
} from 'bootstrap-vue'

import * as paymentService from '@/services/payment'
import BlockPaymentModal from './BlockPaymentModal.vue'

export default {
  components: {
    BButtonGroup,
    BButton,
    BlockPaymentModal,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    blockid: {
      type: String,
      default: '',
    },
    tripid: {
      type: String,
      default: '',
    },
    loads: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      payments: [],
    }
  },
  methods: {
    viewPayment() {
      this.getPaymentOfTrip(this.blockid, this.tripid)
      this.$bvModal.show(`block-payment-modal${this.tripid}${this.blockid}`)
    },

    // get Payment of trip
    getPaymentOfTrip(blockid, tripid) {
      const loadIds = this.loads.map(load => load.load_id)
      paymentService.getPaymentOfTrip({ blockid, tripid, loadIds })
        .then(res => {
          this.payments = res.data.payments
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>

<style>

</style>
